<template>
  <div class="manage">

    <!--    搜索区域开始   -->
    <el-collapse>
      <el-collapse-item title="搜索" name="1">
        <el-form :model="searchForm"
                 :inline="true"
                 label-width="80px">
        <el-form-item label="角色名称" prop="name">
          <el-input placeholder="请输入角色名称" v-model="searchForm.Name"/>
        </el-form-item>
        <el-form-item label="帐号状态" prop="status">
          <el-select v-model="searchForm.Status" placeholder="请选择帐号状态">
            <el-option
              v-for="(item, index) in pageData.Select.Status"
              :key="index"
              :label="item"
              :value="index*1">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间" prop="create_time">
          <el-date-picker
            v-model="searchForm.CreateTimeArray"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '00:00:00']">
          </el-date-picker>
        </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>
    <!--    搜索区域结束   -->

    <!--    分割线-->
    <el-divider></el-divider>

    <!-- 弹窗区域开始 -->
    <el-dialog
      :title="this.scene === 1 ? '新增' : '编辑'"
      :visible.sync="showDialog"
      width="50%"
      :before-close="cancelDialog">
      <!-- 用户的表单信息 -->
      <el-form
        ref="form"
        :inline="false"
        :model="form"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="角色名称" prop="Name">
          <el-input placeholder="请输入角色名称" v-model="form.Name"/>
        </el-form-item>
        <el-form-item label="帐号状态" prop="Status">
          <el-select v-model="form.Status" placeholder="请选择帐号状态" style="width: 100%;">
            <el-option v-for="(item, index) in pageData.Select.Status"
                       :key="index"
                       :label="item"
                       :value="index*1" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDialog">取 消</el-button>
          <el-button type="primary" @click="submitClick">确 定</el-button>
        </span>
    </el-dialog>
    <!-- 弹窗区域结束 -->

    <!--    分配权限-->
    <el-dialog
      title="分配权限"
      :visible.sync="ruleDialog"
      width="50%"
      :before-close="ruleClose">
      <el-tree
        :data="menuTree"
        ref="subRoleTree"
        show-checkbox
        node-key="Id"
        :default-expand-all="false"
        :default-checked-keys="MenuChecked"
        :props="{ children: 'Children', label: 'Name' }">
      </el-tree>
      <!-- 用户的表单信息 -->
      <span slot="footer" class="dialog-footer">
          <el-button @click="ruleClose">取 消</el-button>
          <el-button type="primary" @click="ruleSubmitButton">确 定</el-button>
        </span>
    </el-dialog>

    <!-- 表头按钮区域开始 -->
    <div class="manage-header">
      <div>
        <el-button size="mini" class="el-icon-plus" type="primary" @click="addClick" plain>新增</el-button>
        <el-button size="mini" class="el-icon-search" type="success" @click="searchClick" plain>搜索</el-button>
        <el-button size="mini" class="el-icon-refresh-right" type="warning" @click="resetClick" plain>重置</el-button>
      </div>
    </div>
    <!-- 表头按钮区域结束 -->

    <!-- 表格 -->
    <el-table
       :data="pageData.List"
       style="width: 100%;margin-bottom: 20px;"
       row-key="id"
       border
       v-loading="tableLoad">
      <el-table-column prop="Id" label="Id"/>
      <el-table-column prop="Name" label="角色名称"/>
      <el-table-column prop="StatusText" label="帐号状态"/>
      <el-table-column prop="CreateTime" label="创建时间"/>
      <el-table-column prop="UpdateTime" label="更新时间"/>
      <el-table-column label="操作">
        <template  v-slot="{row}">
          <el-button v-if="row.Id !== 1" size="mini" @click="editClick(row)">编辑</el-button>
          <el-button v-if="row.Id !== 1" size="mini" @click="deleteClick(row)" type="danger">删除</el-button>
          <el-button v-if="row.Id !== 1" size="mini" type="info" @click="ruleClick(row)" plain>权限</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div>
      <el-pagination
        layout="prev, pager, next"
        :total="pageData.Total"
        @current-change="pagingClick" />
    </div>
  </div>
</template>

<script>
import '@/assets/static/css/base.css'
import service from '@/service/System/RoleService'
export default service
</script>
